import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { Button, Menu, MenuItem } from "@material-ui/core";
import styles from "./finance_header_style";
import route_map from "../../config/route_map";
import helper from "../../utils/helper";
import { Iconfont } from "../../lib";
import FormatMessage from "./format_message";

let timer = null;
class FinanceHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      hide_str: "********",
    };
    this.changeUnit = this.changeUnit.bind(this);
  }
  componentDidMount() {
    this.init();
  }
  init() {
    this.updateBalance();
  }

  componentDidUpdate(preProps, preState) {
    if (preProps.asset_unit !== this.props.asset_unit) {
      this.updateBalance();
    }
  }

  //  3秒更新一次资产
  async updateBalance() {
    clearTimeout(timer);
    timer = null;
    try {
      // 总资产
      await this.props.dispatch({
        type: "layout/getTotalAsset",
        payload: {
          unit: this.props.asset_unit,
        },
      });
    } catch (e) {}
    timer = setTimeout(
      () => {
        this.updateBalance();
      },
      this.props.tab == "lever" ? 10000 : 3000
    );
  }

  change = () => {
    window.localStorage.hidden_balance = !this.props.hidden_balance;
    this.props.dispatch({
      type: "layout/save",
      payload: {
        hidden_balance: !this.props.hidden_balance,
      },
    });
  };

  handleToggle = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  close = () => {
    this.setState({
      open: false,
    });
  };

  goto = (key) => (e) => {
    window.location.href = route_map.trace_back + "/" + key;
  };

  changeUnit = () => {
    let newUnit =
      this.props.total_asset && this.props.asset_unit == "BTC" ? "USDT" : "BTC";
    sessionStorage.asset_unit = newUnit;
    this.props.dispatch({
      type: "layout/save",
      payload: {
        asset_unit: newUnit,
      },
    });
  };

  typeChange = (url) => (e) => {
    window.location.href = url;
  };

  render() {
    const { classes, functions, total_asset, tab, userinfo } = this.props;
    let unit = this.props.total_asset ? this.props.total_asset.unit : "";
    const totalAssetRates =
      this.props.total_asset && this.props.total_asset.totalAsset
        ? helper.currencyValue(
            this.props.rates,
            this.props.total_asset.totalAsset,
            unit
          )
        : ["", ""];
    return (
      <div className={classes.finance_title_box}>
        <div className="content">
          <div className={classes.finance_title_con}>
            <div className={classes.finance_title}>
              <div
                id="finance_highcharts"
                style={{ margin: "0 6px 0 0" }}
              ></div>
              <div className={classes.info}>
                <p>
                  {this.props.intl.formatMessage({ id: "总资产折合" })}
                  <Iconfont type="switch" size={24} onClick={this.changeUnit} />
                  <em onClick={this.changeUnit}>{unit}</em>
                </p>
                <strong>
                  {this.props.total_asset && this.props.total_asset.totalAsset
                    ? this.props.hidden_balance
                      ? this.state.hide_str
                      : helper.digits(
                          this.props.total_asset.totalAsset,
                          unit == "BTC" ? 8 : 2
                        )
                    : ""}{" "}
                  {unit}{" "}
                  {this.props.hidden_balance ? (
                    <Iconfont type="hidden" size={24} onClick={this.change} />
                  ) : (
                    <Iconfont type="unhidden" size={24} onClick={this.change} />
                  )}
                </strong>
                <span>
                  {this.props.total_asset &&
                  this.props.total_asset.totalAsset &&
                  this.props.rates[this.props.asset_unit]
                    ? `≈ ${totalAssetRates[0]}${
                        this.props.hidden_balance
                          ? this.state.hide_str
                          : totalAssetRates[1]
                      }`
                    : ""}
                </span>
              </div>
              <div>
                {window.WEB_CONFIG.orgId == 6002 ? (
                  <Button
                    ref={(ref) => (this.anchorRef = ref)}
                    onClick={this.handleToggle}
                    variant="outlined"
                    color="primary"
                    className={classes.btn}
                  >
                    {this.props.intl.formatMessage({ id: "资产证明" })}
                    <Iconfont type="arrowDown" size={20} />
                  </Button>
                ) : (
                  ""
                )}
                <Button
                  variant="outlined"
                  color="primary"
                  href={route_map.finance_record}
                  className={classes.btn}
                >
                  {this.props.intl.formatMessage({ id: "资产记录" })}
                </Button>
              </div>
            </div>
            <div className={classes.header1}>
              <ul>
                {functions && functions["exchange"] ? (
                  <li
                    className={tab == "exchange" ? "active" : ""}
                    onClick={this.typeChange(route_map.finance_list)}
                  >
                    <FormatMessage id="钱包资产" />
                  </li>
                ) : (
                  ""
                )}
                {functions && functions["margin"] ? (
                  <li
                    className={tab == "lever" ? "active" : ""}
                    onClick={this.typeChange(route_map.margin_finance)}
                  >
                    {/* <a href={route_map.margin_finance}> */}
                    {this.props.intl.formatMessage({
                      id: "lever.asset",
                    })}
                    {/* </a> */}
                  </li>
                ) : (
                  ""
                )}
                {functions && functions["futures"] ? (
                  <li
                    className={tab == "future" ? "active" : ""}
                    onClick={this.typeChange(route_map.future_finance)}
                  >
                    {/* <a href={route_map.future_finance}> */}
                    {this.props.intl.formatMessage({
                      id: "永续合约资产",
                    })}
                    {/* </a> */}
                  </li>
                ) : (
                  ""
                )}
                {functions && functions["bonus"] ? (
                  <li
                    className={tab == "bonus" ? "active" : ""}
                    onClick={this.typeChange(route_map.staking_finance)}
                  >
                    {/* <a href={route_map.staking_finance}> */}
                    {this.props.intl.formatMessage({
                      id: "币多多资产",
                    })}
                    {/* </a> */}
                  </li>
                ) : (
                  ""
                )}

                {(functions && !functions.hasOwnProperty("showSubAcco")) ||
                (functions && functions["showSubAcco"]) ? (
                  <li
                    className={tab == "child" ? "active" : ""}
                    onClick={this.typeChange(route_map.finance_child_account)}
                  >
                    <FormatMessage id="子账户资产" />
                  </li>
                ) : (
                  ""
                )}
                {/* {functions && functions["miner"] ? (
                  <li
                    className={tab == "miner" ? "active" : ""}
                    onClick={this.typeChange(route_map.mining_finance)}
                  >
                    {this.props.intl.formatMessage({
                      id: "挖矿资产",
                    })}
                  </li>
                ) : (
                  ""
                )
                } */}
              </ul>
            </div>
          </div>
        </div>
        <Menu
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={this.state.open}
          anchorEl={this.anchorRef}
          onClose={this.close}
        >
          <MenuItem onClick={this.goto("BTC")} className={classes.menu_hover}>
            {this.props.intl.formatMessage({ id: "BTC资产证明" })}
          </MenuItem>
          <MenuItem onClick={this.goto("ETH")} className={classes.menu_hover}>
            {this.props.intl.formatMessage({ id: "ETH资产证明" })}
          </MenuItem>
          <MenuItem onClick={this.goto("USDT")} className={classes.menu_hover}>
            {this.props.intl.formatMessage({ id: "USDT资产证明" })}
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

FinanceHeader.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(injectIntl(FinanceHeader));
